import React from "react";
import { SideBar } from "./sidebar";
import { updateAuction, getAuction, statuses } from "../../models/auctions";
import { getAllOrganization } from "../../models/organization";
import { getAllCategories } from "../../models/categories";
import { getAllGroups, getGroup } from "../../models/groups";
import { getAllMasks, getMask } from "../../models/masks";
import swal from "sweetalert";
import i18n from "../../i18n";
import { AuctionTabs } from "./helper/auction_tabs";
import { Map } from "../helpers/map";
import { HomeBanner } from "../home/homeBanner";
import ToggleButton from "react-toggle-button";

export class EditAuction extends React.Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);

    this.state = {
      auctionId: auctionId,
      lat: 23.588,
      lng: 58.3829,
      input: {},
      errors: {},
      categories: {},
      organizations: {},
      masks: {},
      groups: {},
      is_loading: false,
      is_loaded: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.toggleZakath = this.toggleZakath.bind(this);
    this.toggleFeatured = this.toggleFeatured.bind(this);
    this.toggleAutoApproval = this.toggleAutoApproval.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllCategories();
    this.loadAllOrganisation();
    this.loadAllMasks();
    this.loadAllGroups();
    this.loadAuction();
  }

  toggleChange = () => {
    let input = this.state.input;
    input.is_extra_time = !this.state.input.is_extra_time;
    this.setState({
      input,
    });
  };

  toggleZakath = () => {
    let input = this.state.input;
    input.is_zakath = !this.state.input.is_zakath;
    this.setState({
      input,
    });
  };

  toggleFeatured = () => {
    let input = this.state.input;
    input.is_featured = !this.state.input.is_featured;
    this.setState({
      input,
    });
  };

  toggleAutoApproval = () => {
    let input = this.state.input;
    input.auto_approval = !this.state.input.auto_approval;
    this.setState({
      input,
    });
  };

  async loadAllCategories() {
    const response = await getAllCategories();
    let categories = response.data;
    this.setState({ categories });
  }

  async loadAllOrganisation() {
    const response = await getAllOrganization();
    let organizations = response.data;
    this.setState({ organizations });
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAuction() {
    let id = this.state.auctionId;
    const response = await getAuction(id);
    if (response.success) {
      let auction = response.data;
      let enquiry = auction.enquiry;
      auction.selected_mask = auction.mask;
      this.setState({ input: auction, enquiries: enquiry, is_loaded: true });
    }
  }

  async loadAllMasks() {
    const response = await getAllMasks();
    let masks = response.data;
    this.setState({ masks });
  }

  async loadMask(selected_mask) {
    if (selected_mask) {
      const response = await getMask(selected_mask);
      let result = response.data;
      let input = this.state.input;
      input["class"] = result.class;
      input["start_amount"] = result.start_amount;
      input["bid_increment"] = result.bid_increment;
      this.setState({ input });
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "selected_mask") {
      //this.loadMask(event.target.value);
    }
    if (event.target.name === "group") {
      //this.loadGroup(event.target.value);
    }
  }

  async loadGroup(selected_group) {
    const response = await getGroup(selected_group);
    let result = response.data;
    let input = this.state.input;
    input["start_date"] = result.start_date;
    input["end_date"] = result.end_date;
    input["description"] = result.description;
    input["terms"] = result.terms;
    input["terms_arabic"] = result.terms_arabic;
    input["enquiries"] = result.enquiry;
    this.setState({ input });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      console.log(payload);
      payload.enquiries = this.state.enquiries;
      let id = this.state.auctionId;
      const response = await updateAuction(payload, id);
      if (response && response.data && response.data.id) {
        swal(i18n.t("success"), response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
      } else {
        let error_message = "Not found!!!";
        let error_messages = response.message;
        for (const key in error_messages) {
          if (Object.hasOwnProperty.call(error_messages, key)) {
            let element = error_messages[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  getLocation(lat, lng) {
    let input = this.state.input;
    input.latitude = lat;
    input.longitude = lng;
    this.setState({ lat: lat, lng: lng, input });
  }

  render() {
    let categories = this.state.categories;
    let organizations = this.state.organizations;
    let masks = this.state.masks;
    let groups = this.state.groups;

    let auction = this.state.input;
    //if (auction && auction.latitude && auction.longitude) {
    let auctionLocation = { lat: 23.588, lng: 58.3829 };
    if (auction.latitude && auction.longitude) {
      auctionLocation = {
        lat: Number(auction.latitude),
        lng: Number(auction.longitude),
      };
    }
    let is_loaded = this.state.is_loaded;

    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <AuctionTabs activeTab="details" />

                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Category</label>
                          <select
                            className="form-control"
                            data-placeholder="Select Group"
                            name="category"
                            value={this.state.input.category}
                            onChange={this.handleChange}
                          >
                            <option value="">--Category--</option>
                            {categories.length > 0 &&
                              categories.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.category_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Organization</label>
                          <select
                            className="form-control"
                            data-placeholder="Select Group"
                            name="organization"
                            value={this.state.input.organization}
                            onChange={this.handleChange}
                          >
                            <option value="">--Organization--</option>
                            {organizations.length > 0 &&
                              organizations.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.organization_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("auction_group")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Group"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("group")}--</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("title")}
                          </label>
                          <input
                            type="text"
                            name="title"
                            className="form-control"
                            placeholder={i18n.t("title")}
                            value={this.state.input.title}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("title_ar")}
                          </label>
                          <input
                            type="text"
                            name="title_ar"
                            className="form-control"
                            placeholder={i18n.t("title_ar")}
                            value={this.state.input.title_ar}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      {masks.length > 0 && (
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Mask</label>
                            <select
                              className="form-control"
                              data-placeholder="Select Mask"
                              name="selected_mask"
                              value={this.state.input.selected_mask}
                              onChange={this.handleChange}
                            >
                              <option value="">--Mask--</option>
                              {masks.map((item) => {
                                return (
                                  <option value={item.id}>
                                    {item.mask_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      )}
                      <div className="col-sm-12 col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("class")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Class"
                            name="class"
                            value={this.state.input.class}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("class")}--</option>
                            <option value="D">{i18n.t("diamond")}</option>
                            <option value="G">{i18n.t("gold")}</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-8">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <input
                            name="is_featured"
                            type="checkbox"
                            checked={this.state.input.is_featured}
                            onChange={this.toggleFeatured}
                            style={{ float: "left", margin: "4px" }}
                          />
                          <label
                            className="form-label"
                            style={{ float: "left", marginRight: "1rem" }}
                          >
                            Is Featured
                          </label>

                          <input
                            name="is_zakath"
                            type="checkbox"
                            checked={this.state.input.is_zakath}
                            onChange={this.toggleZakath}
                            style={{ float: "left", margin: "4px" }}
                          />
                          <label
                            className="form-label"
                            style={{ float: "left", marginRight: "1rem" }}
                          >
                            Is Zakath
                          </label>

                          <input
                            name="is_extra_time"
                            type="checkbox"
                            checked={this.state.input.is_extra_time}
                            onChange={this.toggleChange}
                            style={{ float: "left", margin: "4px" }}
                          />
                          <label
                            className="form-label"
                            style={{ float: "left" }}
                          >
                            {i18n.t("extra_time")}
                          </label>
                          {this.state.input.is_extra_time && (
                            <input
                              name="extra_time"
                              type="number"
                              className="form-control"
                              checked={this.state.input.extra_time}
                              onChange={this.handleChange}
                              placeholder={i18n.t("in_minutes")}
                              style={{
                                margin: "0px 8px",
                                width: "10rem",
                                position: "absolute",
                                right: "0.2rem",
                              }}
                            />
                          )}
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="row">
                          <div className="col-sm-6 col-md-6"></div>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("price")}
                          </label>
                          <input
                            type="text"
                            name="start_amount"
                            className="form-control"
                            placeholder={i18n.t("price")}
                            value={this.state.input.start_amount}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("gurantee_amount")}
                          </label>
                          <input
                            type="text"
                            name="guarantee_amount"
                            className="form-control"
                            placeholder={i18n.t("gurantee_amount")}
                            value={this.state.input.guarantee_amount}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("visit_amount")}
                          </label>
                          <input
                            type="text"
                            name="visit_amount"
                            className="form-control"
                            placeholder={i18n.t("visit_amount")}
                            value={this.state.input.visit_amount}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-3">
                        <div className="form-group">
                          <label className="form-label">{i18n.t("vat")}</label>
                          <input
                            type="text"
                            name="vat"
                            className="form-control"
                            placeholder={i18n.t("vat")}
                            value={this.state.input.vat}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("incr_amount")}
                          </label>
                          <input
                            type="text"
                            name="bid_increment"
                            className="form-control"
                            placeholder={i18n.t("incr_amount")}
                            value={this.state.input.bid_increment}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("incr_amount")} (comma seperated values)
                          </label>
                          <input
                            type="text"
                            name="increment_numbers"
                            className="form-control"
                            placeholder="eg: 100,200,300"
                            value={this.state.input.increment_numbers}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("start_date")} (
                            {this.state.input.start_date})
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            placeholder="Email"
                            name="start_date"
                            value={this.state.input.start_date}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("end_date")} ({this.state.input.end_date})
                          </label>
                          <input
                            type="datetime-local"
                            name="end_date"
                            className="form-control"
                            placeholder="Phone"
                            value={this.state.input.end_date}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("reg_start_date")}{" "}
                            {this.state.input.reg_start_date
                              ? "(" + this.state.input.reg_start_date + ")"
                              : ""}
                          </label>

                          <input
                            type="datetime-local"
                            className="form-control"
                            placeholder={i18n.t("reg_start_date")}
                            name="reg_start_date"
                            value={this.state.input.reg_start_date}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("reg_end_date")}{" "}
                            {this.state.input.reg_end_date
                              ? "(" + this.state.input.reg_end_date + ")"
                              : ""}
                          </label>
                          <input
                            type="datetime-local"
                            name="reg_end_date"
                            className="form-control"
                            placeholder={i18n.t("reg_end_date")}
                            value={this.state.input.reg_end_date}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("contract_number")}
                          </label>
                          <input
                            type="text"
                            name="contract_number"
                            className="form-control"
                            placeholder={i18n.t("contract_number")}
                            value={this.state.input.contract_number}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("payment_type")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Group"
                            name="payment_type"
                            value={this.state.input.payment_type}
                            onChange={this.handleChange}
                          >
                            <option value="">--Select--</option>
                            <option value="Online">{i18n.t("online")}</option>
                            <option value="Offline">{i18n.t("offline")}</option>
                            <option value="Both">
                              Supports both Online & Offline
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("Enrollment amount")}
                          </label>
                          <input
                            type="text"
                            name="payment_amount"
                            className="form-control"
                            placeholder={i18n.t("Enrollment amount")}
                            value={this.state.input.payment_amount}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Auto Approval</label>
                          <ToggleButton
                            inactiveLabel={"No"}
                            activeLabel={"Yes"}
                            value={this.state.input.auto_approval}
                            className="form-control m-auto"
                            onToggle={(value) => {
                              let { input } = this.state;
                              input.auto_approval = !value;
                              this.setState({
                                input,
                              });
                            }}
                          />
                          {/* <input
                            name="auto_approval"
                            type="checkbox"
                            checked={this.state.input.auto_approval}
                            onChange={this.toggleAutoApproval}
                            style={{ float: "left", margin: "4px" }}
                          /> */}
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-3">
                        <div className="form-group">
                          <label className="form-label">Auto Bidding.?</label>
                          <ToggleButton
                            inactiveLabel={"No"}
                            activeLabel={"Yes"}
                            value={this.state.input.has_auto_bidding}
                            className="form-control m-auto"
                            onToggle={(value) => {
                              let { input } = this.state;
                              input.has_auto_bidding = !value;
                              this.setState({
                                input,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12">
                        <div className="row">
                          <div className="col-sm-4 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("is_vat_included")}
                              </label>
                              <ToggleButton
                                inactiveLabel={i18n.t("no")}
                                activeLabel={i18n.t("yes")}
                                value={this.state.input.is_vat_included}
                                className="form-control m-auto"
                                onToggle={(value) => {
                                  let { input } = this.state;
                                  input.is_vat_included = !value;
                                  this.setState({
                                    input,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("is_service_charge_included")}
                              </label>
                              <ToggleButton
                                inactiveLabel={i18n.t("no")}
                                activeLabel={i18n.t("yes")}
                                value={
                                  this.state.input.is_service_charge_included
                                }
                                className="form-control m-auto"
                                onToggle={(value) => {
                                  let { input } = this.state;
                                  input.is_service_charge_included = !value;
                                  this.setState({
                                    input,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("is_service_charge_vat_included")} -{" "}
                              </label>
                              <ToggleButton
                                inactiveLabel={i18n.t("no")}
                                activeLabel={i18n.t("yes")}
                                value={
                                  this.state.input
                                    .is_service_charge_vat_included
                                }
                                className="form-control m-auto"
                                onToggle={(value) => {
                                  let { input } = this.state;
                                  input.is_service_charge_vat_included = !value;
                                  this.setState({
                                    input,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("status")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Change Status"
                            name="status"
                            value={this.state.input.status}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("status")}--</option>
                            {Object.keys(statuses).map(function (key) {
                              return (
                                <option value={key}>{statuses[key]}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("description")}
                          </label>
                          <textarea
                            className="form-control"
                            name="description"
                            value={this.state.input.description}
                            onChange={this.handleChange}
                            rows={10}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("description")} ({i18n.t("arabic")})
                          </label>
                          <textarea
                            className="form-control"
                            name="description_ar"
                            value={this.state.input.description_ar}
                            onChange={this.handleChange}
                            style={{ direction: "rtl" }}
                            rows={10}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("terms_conditions")}
                          </label>
                          <textarea
                            className="form-control"
                            name="terms"
                            value={this.state.input.terms}
                            onChange={this.handleChange}
                            rows={10}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("terms_conditions")} (Ar)
                          </label>
                          <textarea
                            style={{ direction: "rtl" }}
                            className="form-control"
                            name="terms_arabic"
                            value={this.state.input.terms_arabic}
                            onChange={this.handleChange}
                            rows={10}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="row">
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("location")}
                              </label>
                              <input
                                type="text"
                                name="location"
                                className="form-control"
                                placeholder={i18n.t("location")}
                                value={this.state.input.location}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("location") +
                                  " (" +
                                  i18n.t("arabic") +
                                  ")"}
                              </label>
                              <input
                                type="text"
                                name="location_ar"
                                className="form-control"
                                placeholder={
                                  i18n.t("location") +
                                  " (" +
                                  i18n.t("arabic") +
                                  ")"
                                }
                                value={this.state.input.location_ar}
                                onChange={this.handleChange}
                                style={{ direction: "rtl" }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("location")}
                              </label>

                              {is_loaded && (
                                <Map
                                  getLocation={this.getLocation}
                                  defaultLocation={auctionLocation}
                                />
                              )}

                              <div className="row mt-3">
                                <div className="col-sm-6 col-md-6">
                                  <input
                                    type="text"
                                    name="latitude"
                                    value={this.state.lat}
                                    className="form-control"
                                    onChange={(event) => {
                                      this.setState({
                                        lat: event.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-sm-6 col-md-6">
                                  <input
                                    type="text"
                                    name="longitude"
                                    value={this.state.lng}
                                    className="form-control"
                                    onChange={(event) => {
                                      this.setState({
                                        lng: event.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer" style={{ textAlign: "right" }}>
                    <button type="submit" className="btn btn-primary">
                      Update Auction
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
