import React from "react";
//import { Link } from "react-router-dom";
import { enrollUser, getAuction } from "../models/auctions";
import { getLoggedUser } from "../state/user";
import i18n from "../i18n";
import swal from "sweetalert";
import { createThawaniCustomer, createThawaniSession } from "../models/thawani";
import { Spinner } from "react-bootstrap";
import {
  thawaniPaymentUrl,
  thawaniConfig,
  paymentSuccessUrl,
  paymentCancelledUrl,
} from "../constants/global";
import { Link } from "react-router-dom";
import logoThawani from "../logoThawani.svg";
import { Modal } from "react-bootstrap";
import { getUserInfo } from "../models/users";
import { VisitNow } from "./home/helpers/visitNow";
import FileInput from "./helpers/fileInput";
import { checkBlackList } from "../models/blacklist";
import { HomeBanner } from "./home/homeBanner";
import { WalletFund } from "./helpers/walletFund";

//let is_logged = localStorage.getItem("is_logged_in") ? true : false;
const user = getLoggedUser();
let lng = localStorage.getItem("I18N_LANGUAGE");
const USER_FIELDS = {
  file_id_number: i18n.t("attach_id_no"),
  authority_name: i18n.t("auth_name"),
  account_number: i18n.t("account_number"),
  cr_number: i18n.t("cr_number"),
  resident_card_number: i18n.t("personal_card_no"),
  vat_number: i18n.t("vat_number"),
  file_cr_number: i18n.t("attacth_cr_copy"),
  file_auth_letter: i18n.t("auth_letter"),
  file_vat_certificate: i18n.t("attach_vat"),
};
export class Enroll extends React.Component {
  constructor() {
    super();
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      online_type: "local",
      show_terms_panel: false,
      show_bank_panel: false,
      ptype: "offline",
      input: { ...user },
      is_company: user.is_company || 0,
      t_c_acept: false,
      payment: {},
      auction: auctionId,
      is_payment: false,
      civilCard: null,
      crFile: null,
      bankGuaranteeFile: null,
      bankAccountFile: null,
      signature: null,
      is_payment_loading: false,
      is_loading: false,
      show_success_message: false,
      terms: "",
      is_online_payment_needed: false,
      is_online_payment_mandatory: false,
      need_online_payment: false,
      thawani_data_id: "",
      enrollData: [],
      selected_price: 20000,
      show_payment_details: false,
      auction_details: {},
      open_popup: false,
      file_receipt: null,
      is_not_wallet_balance: false,
      is_profile_completed: true,
      wallet_amount: 0,
      is_blacklisted: false,
      missingField: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.saveEnroll = this.saveEnroll.bind(this);
    this.createCustomer = this.createCustomer.bind(this);
    this.gotoPayment = this.gotoPayment.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    let { input } = this.state;
    let fieldsToCheck = [
      "account_number",
      "resident_card_number",
      "file_id_number",
    ];
    if (input.is_company) {
      fieldsToCheck = [
        "authority_name",
        "account_number",
        "cr_number",
        "resident_card_number",
        "vat_number",
        "file_id_number",
        "file_cr_number",
        "file_auth_letter",
        "file_vat_certificate",
      ];
    }

    let missingField = [];
    fieldsToCheck.forEach((field) => {
      if (
        input[field] === "" ||
        input[field] === null ||
        input[field] === false
      ) {
        this.setState({ is_profile_completed: false });
        missingField.push(field);
      }
    });
    console.log(missingField);
    this.setState({ missingField: missingField });

    this.loadAuction();
    this.checkUserWallet();
    this.checkBlacklist();
  }

  async checkBlacklist() {
    const response = await checkBlackList();
    if (response.success) {
      let { data } = response;
      this.setState({ is_blacklisted: data.is_blacklisted });
    }
  }
  async loadAuction() {
    let id = this.state.auction;
    const response = await getAuction(id);
    if (response) {
      let auction = response.data;
      this.setState({ auction_details: auction });
      let payment_needed = false;
      if (
        auction.payment_type === "Online" ||
        auction.payment_type === "Both"
      ) {
        payment_needed = true;
        if (auction.payment_type === "Online") {
          this.setState({
            is_online_payment_mandatory: true,
            show_payment_details: true,
            need_online_payment: true,
          });
        }
        if (auction.payment_amount) {
          let paymentAmount = auction.payment_amount * 1000;
          this.setState({
            selected_price: paymentAmount,
          });
        }
      }
      this.setState({
        terms: auction.terms,
        terms_ar: auction.terms_arabic,
        is_online_payment_needed: payment_needed,
      });
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  handlePayment(event) {
    let payment = this.state.payment;
    payment[event.target.name] = event.target.value;
    this.setState({ payment });
  }

  onFileChange = (event) => {
    console.log(event);
    console.log("Selected file Parent:", event.target.files[0]);
    if (!this.validateFileSize(event.target.files[0])) {
      swal("Failed", "File size should be less than 5MB", "error");
      return;
    }

    if (event.target.name === "file_receipt") {
      this.setState({ file_receipt: event.target.files[0] });
    }
  };

  validateFileSize(file) {
    const MAX_FILE_SIZE = 5120; // 5MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      return;
    }
    return true;
  }

  async saveEnroll(event) {
    event.preventDefault();
    let { auction_details } = this.state;
    this.setState({ is_loading: true });
    let amount = auction_details.payment_amount;
    if (this.state.ptype === "online") {
      let { online_type } = this.state;
      let two_percent = (parseFloat(amount) * 2) / 100;
      let credit_amount = parseFloat(amount) + parseFloat(two_percent);

      let one_five_percent = (parseFloat(amount) * 1.5) / 100;
      let debit_amount = parseFloat(amount) + parseFloat(one_five_percent);
      if (online_type === "local") {
        amount = debit_amount;
      }
      if (online_type === "credit") {
        amount = credit_amount;
      }
    }
    this.setState({
      selected_price: amount * 1000,
    });
    const formData = new FormData();
    formData.append("auction", this.state.auction);
    formData.append("enroll_name", this.state.input.name);
    formData.append("identity_type", this.state.input.identity_type);

    formData.append("bank", this.state.input.bank);
    formData.append("account_number", this.state.input.account_number);
    formData.append("beneficiary", this.state.input.beneficiary);
    formData.append("receipt_number", this.state.input.receipt_number);

    formData.append("is_company", this.state.is_company ? true : false);
    formData.append("is_offline", !this.state.need_online_payment);
    formData.append("ptype", this.state.ptype);
    formData.append("identity_type", "Civil Card");
    formData.append("amount", amount);
    // formData.forEach((value, key) => {
    //   console.log(`${key}:`, value);
    // });
    // return;
    if (this.state.file_receipt) {
      formData.append(
        "file_receipt",
        this.state.file_receipt,
        this.state.file_receipt.name
      );
    }

    this.setState({ is_payment_loading: true });
    const response = await enrollUser(formData);
    if (response.success) {
      this.setState({ show_success_message: true });
      this.setState({ is_loading: false });
      this.setState({ is_payment_loading: false });
      if (this.state.ptype === "online") {
        this.setState({ show_success_message: false });
        this.createCustomer(response.data);
        this.setState({ is_payment: true, enrollData: response.data });
      }
    } else {
      this.setState({ is_loading: false });
      this.setState({ is_payment_loading: false });
      swal("Failed", response.message, "error");
      return;
    }
  }

  async gotoPayment() {
    let enroll = this.state.enrollData;
    let customer_id = this.state.thawani_data_id;
    let queryParams = `?gid=${enroll.group_id}&eid=${enroll.id}&auc=${
      this.state.auction
    }&amt=${Number(this.state.selected_price) / 1000}`;
    let payload = {
      client_reference_id: user.email,
      mode: "payment",
      products: [
        {
          name: "Mzadcom Auction",
          quantity: 1,
          unit_amount: parseInt(this.state.selected_price),
        },
      ],
      success_url: paymentSuccessUrl + queryParams,
      cancel_url: paymentCancelledUrl + queryParams,
      customer_id: customer_id,
      metadata: {
        "Customer name": user.name,
        "Order id": enroll.id,
        user: user.id,
        auction: this.state.auction,
      },
    };

    let thw = await createThawaniSession(payload);
    this.setState({ is_payment_loading: false });

    if (thw && thw.data && thw.success && thw.data.session_id) {
      let session_id = thw.data.session_id;
      localStorage.setItem("invoice", thw.data.invoice);
      window.location.href =
        thawaniPaymentUrl +
        session_id +
        "?key=" +
        thawaniConfig.publishable_key;
    }
  }

  async createCustomer(enroll) {
    window.scrollTo(0, 0);
    if (user && (user.email || user.username)) {
      let thw = await createThawaniCustomer({
        client_customer_id: user.email ? user.email : user.username,
      });
      if (thw && thw.data) {
        localStorage.setItem("thawani_customer_id", thw.data.id);
        this.setState({ thawani_data_id: thw.data.id });
        this.gotoPayment();
      }
    }
  }

  closeModal() {
    this.setState({ open_popup: false });
  }

  openModal() {
    this.setState({ open_popup: true });
  }

  async checkUserWallet() {
    const response = await getUserInfo(user.id);
    if (response && response.success) {
      let usr = response.data;
      if (usr) {
        user.wallet_amount = usr.wallet_amount;
        localStorage.setItem("mzad_user_info", JSON.stringify(user));
        this.setState({ wallet_amount: usr.wallet_amount });
        if (
          Number(usr.wallet_amount) === 0 ||
          Number(usr.wallet_amount) <
            Number(this.state.auction_details.payment_amount)
        ) {
          this.setState({ is_not_wallet_balance: true });
        }
      }
    }
  }

  render() {
    let {
      ptype,
      is_not_wallet_balance,
      is_profile_completed,
      auction_details,
      wallet_amount,
      is_blacklisted,
      missingField,
    } = this.state;
    let two_percent =
      (parseFloat(this.state.auction_details.payment_amount) * 2) / 100;
    let credit_amount =
      parseFloat(this.state.auction_details.payment_amount) +
      parseFloat(two_percent);

    let one_five_percent =
      (parseFloat(this.state.auction_details.payment_amount) * 1.5) / 100;
    let debit_amount =
      parseFloat(this.state.auction_details.payment_amount) +
      parseFloat(one_five_percent);

    // let visit_sts_chk =
    //   auction_details.visit_status &&
    //   (auction_details.visit_status === "NP" ||
    //     auction_details.visit_status === "N" ||
    //     auction_details.visit_status === "H" ||
    //     auction_details.visit_status === "P")
    //     ? false
    //     : true;

    let is_visit_message =
      Number(auction_details.visit_amount) > 0 &&
      auction_details.is_visit_active
        ? true
        : false;

    let is_paynow_disable = false;
    if (
      !this.state.t_c_acept ||
      this.state.is_payment ||
      !is_profile_completed ||
      (ptype === "wallet" && is_not_wallet_balance) ||
      //auction_details.is_enroll_requested ||
      auction_details.is_enrolled ||
      is_visit_message ||
      is_blacklisted
    ) {
      is_paynow_disable = true;
    }

    let can_show_submit = true;
    if (ptype === "wallet" && is_not_wallet_balance) {
      can_show_submit = false;
    }

    let online_buttons =
      "btn btn-outline-success d-flex w-100 m-2 fs-5rem-mob rounded-0 align-items-center justify-content-center";
    let online_buttons_active =
      "btn btn-success d-flex w-100 m-2 fs-5rem-mob rounded-0 align-items-center justify-content-center";

    return (
      <React.Fragment>
        <div className="page-container enroll">
          <HomeBanner callbackForSupport={this.props.callbackForSupport} />

          <section className="sptb mt-5 mx-5 mb-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6">
                  <div
                    className="blue-container"
                    style={{ borderRadius: "20px" }}
                  >
                    <div className="mx-2 mb-3">
                      <img
                        height="26"
                        width="26"
                        alt=""
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/fluent-mdl2_open-client payment.png"
                        }
                      />
                      <span className="custom-text5 text-white mx-1">
                        {i18n.t("enroll")}
                      </span>
                    </div>
                    <div
                      className="card mb-xl-0"
                      style={{ borderRadius: "20px" }}
                    >
                      <div
                        className="card-header"
                        style={{ borderRadius: "20px" }}
                      >
                        <h3 className="card-title gap-2 d-flex align-items-center">
                          <img
                            height="22px"
                            width="22px"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/ri_auction-fill (1).png"
                            }
                            alt=""
                          />
                          {lng === "ar"
                            ? auction_details.title_ar
                            : auction_details.title}
                        </h3>
                      </div>
                      <div
                        className="card-body"
                        style={{ padding: "0rem 1rem 1rem 1rem" }}
                      >
                        {is_blacklisted && (
                          <div>
                            <div className="border border-danger text-center mb-5 p-3">
                              <div className="alert alert-danger mb-3">
                                {i18n.t("user_blacklisted_message")}
                              </div>
                            </div>
                          </div>
                        )}

                        {!is_profile_completed && (
                          <div>
                            <div className="border border-warning text-center mb-5 p-3">
                              <div className="alert alert-warning mb-3">
                                {i18n.t("profile_completion_message")}
                              </div>
                              <div>
                                <Link
                                  to="/profile"
                                  className="btn btn-outline-warning "
                                >
                                  {i18n.t("profile")}
                                </Link>
                              </div>
                              <div className="form-text text-muted">
                                {i18n.t("missing_fields")} :
                                {Object.entries(missingField).map((t, k) => (
                                  <span key={k}>
                                    <span className="badge text-danger">
                                      {USER_FIELDS[missingField[k]]}
                                    </span>
                                    &nbsp;
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}

                        {is_visit_message && (
                          <div>
                            <div className="border border-danger text-center mb-5 p-3">
                              <div className="alert alert-danger mb-3">
                                {auction_details.is_visit_active
                                  ? i18n.t("visit_warning_message")
                                  : i18n.t("visit_warning_message_np")}
                              </div>
                              {auction_details.visit_amount &&
                                auction_details.is_visit_active && (
                                  <div>
                                    <VisitNow
                                      amount={auction_details.visit_amount}
                                      type="sitevisit"
                                      id={auction_details.id}
                                      gid={auction_details.group}
                                      is_group={auction_details.is_grouped}
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                        )}

                        {auction_details.is_enrolled && (
                          <div>
                            <div className="border border-success text-center mb-5 p-3">
                              <div className="alert alert-success mb-3">
                                {i18n.t("enrolled_message")}
                              </div>
                              <div>
                                <Link
                                  to="/"
                                  className="btn btn-outline-success "
                                >
                                  {i18n.t("home")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* {auction_details.is_enroll_requested && (
                          <div>
                            <div className="border border-success text-center mb-5 p-3">
                              <div className="alert alert-success mb-3">
                                {i18n.t("enroll_warning_message")}
                              </div>
                              <div>
                                <Link
                                  to="/"
                                  className="btn btn-outline-success "
                                >
                                  {i18n.t("home")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        )} */}
                        <form
                          id="enrollForm"
                          method="get"
                          className="form-horizontal mb-0"
                        >
                          <div
                            id="rootwizard"
                            className="border pt-0 rounded-4"
                            style={{ overflow: "hidden" }}
                          >
                            <div className="p-3">
                              {/** Enroll Section */}
                              <div className="" id="first">
                                <div className="">
                                  <div className="row mb-3">
                                    <div className="col-sm">
                                      <fieldset className="scheduler-border">
                                        <div className="d-flex justify-content-between fs-7rem-mob">
                                          <legend className="h6 w-50">
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/wallet-icon.png"
                                              }
                                              alt="wallet"
                                            />
                                            &nbsp;
                                            {i18n.t("pay_type")}
                                          </legend>
                                          {this.state.auction_details
                                            .payment_amount && (
                                            <div className="">
                                              <span className="custom-text3_new">
                                                <b>
                                                  {i18n.t("enroll_amount")} :
                                                  {
                                                    this.state.auction_details
                                                      .payment_amount
                                                  }
                                                  &nbsp;{i18n.t("omr")}
                                                </b>
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                        <div className="btn-group d-flex border rounded-pill btn-toggle-custom">
                                          <button
                                            type="button"
                                            style={{ "text-wrap": "nowrap" }}
                                            className={
                                              ptype === "offline"
                                                ? "btn btn-info w-100 rounded-4 border-0 rounded-pill text-white"
                                                : "btn btn-outline-dark w-100 border-0 rounded-pill"
                                            }
                                            onClick={(event) => {
                                              this.setState({
                                                ptype: "offline",
                                                need_online_payment: false,
                                                show_payment_details: false,
                                                show_wallet_fund_screen: false,
                                              });
                                            }}
                                          >
                                            <i className="fa fa-bank" />
                                            &nbsp;
                                            {i18n.t("bank_transfer")}
                                          </button>
                                          <button
                                            type="button"
                                            className={
                                              ptype === "online"
                                                ? "btn btn-info w-100 rounded-4 border-0 rounded-pill text-white"
                                                : "btn btn-outline-dark w-100 border-0 rounded-pill"
                                            }
                                            onClick={(event) => {
                                              this.setState({
                                                ptype: "online",
                                                need_online_payment: true,
                                                show_payment_details: true,
                                                show_wallet_fund_screen: false,
                                              });
                                            }}
                                          >
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/new-phone.png"
                                              }
                                              alt="wallet"
                                              width="15"
                                            />
                                            &nbsp;
                                            {i18n.t("online")}
                                          </button>
                                          <button
                                            type="button"
                                            className={
                                              ptype === "wallet"
                                                ? "btn btn-info w-100 rounded-4 border-0 rounded-pill text-white"
                                                : "btn btn-outline-dark w-100 border-0 rounded-pill"
                                            }
                                            onClick={(event) => {
                                              this.checkUserWallet();
                                              this.setState({
                                                ptype: "wallet",
                                                need_online_payment: false,
                                                show_payment_details: false,
                                                show_wallet_fund_screen: false,
                                              });
                                            }}
                                          >
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/wallet-icon.png"
                                              }
                                              alt="wallet"
                                            />
                                            &nbsp;
                                            {i18n.t("wallet")}
                                          </button>
                                        </div>
                                      </fieldset>
                                    </div>
                                  </div>

                                  {ptype === "offline" && (
                                    <div>
                                      <div className="form-group mx-2 mt-3 ">
                                        <div className="col-md-12 col-md-6 col-md-3">
                                          <label
                                            className="custom-text3 mb-2"
                                            for="GuaranteeamountDepositandRefundPolicy"
                                          >
                                            <img
                                              width="23px"
                                              height="23px"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/image 25.png"
                                              }
                                              alt=""
                                            />
                                            {i18n.t(
                                              "guarantee_amount_policy_head"
                                            )}
                                          </label>
                                          <div
                                            className="form-control overflow-auto custom-textarea border-25"
                                            style={{ height: "170px" }}
                                          >
                                            <p className="p-0 m-0">
                                              {i18n.t(
                                                "guarantee_amount_policy_1"
                                              )}
                                            </p>
                                            <p className="p-0 m-0">
                                              {i18n.t(
                                                "guarantee_amount_policy_2"
                                              )}
                                            </p>
                                            <p className="p-0 m-0">
                                              {i18n.t(
                                                "guarantee_amount_policy_3"
                                              )}
                                            </p>
                                            <p className="p-0 m-0">
                                              {i18n.t(
                                                "guarantee_amount_policy_4"
                                              )}
                                            </p>
                                            <p className="p-0 m-0">
                                              {i18n.t(
                                                "guarantee_amount_policy_5"
                                              )}
                                            </p>
                                            <p className="p-0 m-0">
                                              {i18n.t(
                                                "guarantee_amount_policy_6"
                                              )}
                                            </p>
                                            <p className="p-0 m-0">
                                              {i18n.t(
                                                "guarantee_amount_policy_7"
                                              )}
                                            </p>
                                            <p className="p-0 m-0">
                                              {i18n.t(
                                                "guarantee_amount_policy_8"
                                              )}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="col-md-12 col-md-6 col-md-3 mt-2">
                                          <div>
                                            <label for="bankname">
                                              <span style={{ color: "black" }}>
                                                *
                                              </span>
                                              {i18n.t("bank")}
                                            </label>
                                          </div>
                                          <div className="mt-2">
                                            <input
                                              className="form-control custom-input rounded-pill"
                                              type="text"
                                              id="bankname"
                                              name="bank"
                                              placeholder={i18n.t(
                                                "please_enter_bank_name"
                                              )}
                                              value={this.state.input.bank}
                                              onChange={this.handleChange}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-12 col-md-6 col-md-3 mt-2">
                                          <div>
                                            <label for="bankaccount">
                                              <span style={{ color: "black" }}>
                                                *
                                              </span>
                                              {i18n.t("account_number")}
                                            </label>
                                          </div>
                                          <div className="mt-2">
                                            <input
                                              className="form-control custom-input rounded-pill"
                                              type="text"
                                              id="bankaccount"
                                              name="account_number"
                                              placeholder={i18n.t(
                                                "please_enter_bank_account"
                                              )}
                                              value={
                                                this.state.input.account_number
                                              }
                                              onChange={this.handleChange}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-12 col-md-6 col-md-3 mt-2">
                                          <div>
                                            <label for="beneficiaryname">
                                              <span style={{ color: "black" }}>
                                                *
                                              </span>
                                              {i18n.t("beneficiary")}
                                            </label>
                                          </div>
                                          <div className="mt-2">
                                            <input
                                              className="form-control custom-input rounded-pill"
                                              type="text"
                                              id="beneficiaryname"
                                              name="beneficiary"
                                              placeholder={i18n.t(
                                                "please_enter_beneficiary_name"
                                              )}
                                              value={
                                                this.state.input.beneficiary
                                              }
                                              onChange={this.handleChange}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-12 col-md-6 col-md-3 mt-2">
                                          <div>
                                            <label for="receiptnumber">
                                              <span style={{ color: "black" }}>
                                                *
                                              </span>
                                              {i18n.t("receipt")}
                                            </label>
                                          </div>
                                          <div className="mt-2">
                                            <input
                                              className="form-control custom-input rounded-pill"
                                              type="text"
                                              id="receiptnumber"
                                              name="receipt_number"
                                              placeholder={i18n.t(
                                                "please_enter_receipt_number"
                                              )}
                                              value={
                                                this.state.input.receipt_number
                                              }
                                              onChange={this.handleChange}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <label
                                          for="file_receipt"
                                          className="form-label"
                                          style={{ textAlign: "justify" }}
                                        >
                                          {i18n.t("receipt")}{" "}
                                        </label>

                                        <FileInput
                                          className="form-control"
                                          placeholder={i18n.t("choose_file")}
                                          default_placeholder={i18n.t(
                                            "upload_receipt"
                                          )}
                                          id="file_receipt"
                                          name="file_receipt"
                                          disabled={!is_profile_completed}
                                          onChange={this.onFileChange}
                                        />
                                      </div>
                                    </div>
                                  )}

                                  {ptype === "online" && (
                                    <div className="col-md-12 col-md-6 col-md-3 mt-3">
                                      <label
                                        className="custom-text3 mb-2"
                                        for="GuaranteeamountDepositandRefundPolicy"
                                      >
                                        <img
                                          width="23px"
                                          height="23px"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/image 25.png"
                                          }
                                          alt=""
                                        />
                                        {i18n.t(
                                          "online_deposit_and_refund_policy"
                                        )}
                                      </label>
                                      <div
                                        className="form-control overflow-auto custom-textarea border-25"
                                        style={{ height: "170px" }}
                                      >
                                        <p className="p-0 m-0">
                                          1) Those willing to participate in the
                                          e-auction must deposit an Guarantee
                                          amount.{" "}
                                        </p>
                                        <p className="p-0 m-0">
                                          2) The Guarantee amount is refundable.
                                          However, the Service Charges for the
                                          Online Payment is not refundable,
                                          which is 1.5% for local cards and 2%
                                          for debit cards.{" "}
                                        </p>
                                        <p className="p-0 m-0">
                                          3) The guarantee amount is separate
                                          from the value of the items offered in
                                          the auction.{" "}
                                        </p>
                                        <p className="p-0 m-0">
                                          4) The Guarantee amount may vary from
                                          an item (a commodity) to another, and
                                          it may be a fixed amount or a
                                          percentage of the present value of the
                                          offered commodity(item).
                                        </p>
                                        <p className="p-0 m-0">
                                          5) The Guarantee amount is not
                                          automatically returned. In case of
                                          delay, Mzadcom will not bear any delay
                                          in refund.
                                        </p>
                                        <p className="p-0 m-0">
                                          6) The Guarantee amount will return to
                                          the Bidders wallet and Bidders can
                                          request for refund.
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="d-flex justify-content-between ps-1 mt-3 mb-3">
                                  {ptype === "wallet" && (
                                    <div className="nav-btn">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/images/lucide_wallet.png"
                                        }
                                        alt=""
                                      />
                                      <span className="custom-text3_new">
                                        {i18n.t("balance")}: {wallet_amount}
                                        &nbsp;
                                        {i18n.t("omr")}
                                      </span>
                                    </div>
                                  )}
                                </div>

                                {ptype === "wallet" &&
                                  is_not_wallet_balance && (
                                    <div>
                                      <div className="alert alert-danger">
                                        {i18n.t("insufficient_wallet_balance")}
                                      </div>
                                      <button
                                        type="button"
                                        className="btn btn-outline-warning btn-sm"
                                        onClick={() => {
                                          this.setState({
                                            show_wallet_fund_screen:
                                              !this.state
                                                .show_wallet_fund_screen,
                                          });
                                        }}
                                      >
                                        {!this.state
                                          .show_wallet_fund_screen && (
                                          <div>
                                            <i className="fa fa-plus"></i>&nbsp;
                                            {i18n.t("add_fund")}
                                          </div>
                                        )}
                                        {this.state.show_wallet_fund_screen && (
                                          <div>
                                            <i className="fa fa-minus"></i>
                                            &nbsp;
                                            {/* {i18n.t("hide")} */}
                                          </div>
                                        )}
                                      </button>
                                    </div>
                                  )}

                                {this.state.show_wallet_fund_screen && (
                                  <div className="mt-3">
                                    <WalletFund />
                                  </div>
                                )}

                                {this.state.show_payment_details && (
                                  <div className="form-group">
                                    <div className="text-center mb-3">
                                      <div className="m-3">
                                        <img
                                          src={logoThawani}
                                          alt=""
                                          height={30}
                                        />
                                      </div>
                                      <div className="m-3">
                                        {i18n.t("redirect_to_thawani")}
                                      </div>
                                    </div>

                                    <div className="d-flex w-100 px-0">
                                      <div className="w-50 p-2 px-1">
                                        <div
                                          className={
                                            this.state.online_type === "local"
                                              ? online_buttons_active
                                              : online_buttons
                                          }
                                          style={{ height: "65px" }}
                                          onClick={() => {
                                            this.setState({
                                              online_type: "local",
                                            });
                                          }}
                                        >
                                          {i18n.t("local_cards_ecommended")}
                                        </div>
                                      </div>
                                      <div className="w-50 p-2 px-1">
                                        <div
                                          className={
                                            this.state.online_type === "credit"
                                              ? online_buttons_active
                                              : online_buttons
                                          }
                                          style={{ height: "65px" }}
                                          onClick={() => {
                                            this.setState({
                                              online_type: "credit",
                                            });
                                          }}
                                        >
                                          {i18n.t("credit_cards_coast_2")}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {can_show_submit && (
                                  <div className="form-group">
                                    <div className="form-group">
                                      <input
                                        className=""
                                        type="checkbox"
                                        placeholder=""
                                        name="t_c"
                                        checked={this.state.t_c_acept}
                                        onChange={(event) => {
                                          this.setState({
                                            t_c_acept: !this.state.t_c_acept,
                                          });
                                        }}
                                      />{" "}
                                      <span>
                                        <small className="text-muted ">
                                          {i18n.t("agree_to_terms")}
                                        </small>
                                        <small className="text text-danger">
                                          (*)
                                        </small>
                                      </span>
                                    </div>
                                  </div>
                                )}

                                <div className="form-group row clearfix">
                                  {can_show_submit && (
                                    <div className="text-center mb-3 mt-3">
                                      <button
                                        type="button"
                                        className="btn btn-color rounded-pill mb-0 me-2 text-white"
                                        onClick={(event) => {
                                          this.saveEnroll(event);
                                        }}
                                        disabled={is_paynow_disable}
                                      >
                                        {!this.state.is_payment && (
                                          <span>{i18n.t("save_continue")}</span>
                                        )}

                                        {this.state.is_payment && (
                                          <span>Loading...</span>
                                        )}
                                      </button>
                                    </div>
                                  )}

                                  {(this.state.is_loading ||
                                    this.state.is_payment_loading) && (
                                    <div
                                      className="text-center mt-3"
                                      style={{ textAlign: "center" }}
                                    >
                                      <Spinner animation="border" />
                                    </div>
                                  )}

                                  {!this.state.is_loading &&
                                    this.state.show_success_message && (
                                      <div>
                                        <div className="text text-success text-center">
                                          Enrollment completed!!!.. Go to{" "}
                                          <Link
                                            className="text text-danger"
                                            to={
                                              "/auction-details/" +
                                              this.state.auction
                                            }
                                          >
                                            Auction
                                          </Link>{" "}
                                          page and continue your bidding!!!
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>

                              {/** Enroll section ends */}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4">
                  <div className="card mb-xl-0 rounded-4 border">
                    <h2
                      className="accordion-header fs-5 fs-md-4"
                      id="flush-headingOne"
                    >
                      <button
                        className="w-100 rounded rounded-4 flex-fill px-3 py-1 bg-light d-flex gap-1 border justify-content-between"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="true"
                        aria-controls="flush-collapseOne"
                        onClick={() => {
                          this.setState({
                            show_terms_panel: !this.state.show_terms_panel,
                          });
                        }}
                      >
                        <div>
                          <img
                            className="img-fluid"
                            width="25"
                            height="26"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/Rectangle 222.png"
                            }
                            alt=""
                          />
                          <span className="custom-text3 m-0 p-1">
                            {i18n.t("terms_conditions")}
                          </span>
                        </div>
                        <div>
                          <i
                            className={
                              this.state.show_terms_panel
                                ? "fa fa-angle-up p-1 px-2 rounded-circle border"
                                : "fa fa-angle-down p-1 px-2 rounded-circle border"
                            }
                            style={{ background: "#e8f4fa" }}
                          />
                        </div>
                      </button>
                    </h2>
                    <div className="card-body p-2 py-0">
                      {this.state.show_terms_panel && (
                        <div className="border rounded p-2 d-flex my-3 justify-content-between bg-light">
                          <div className="d-flex gap-3 align-items-center">
                            <i
                              className="fa fa-file-pdf-o"
                              style={{ color: "#FF0000", fontSize: "2rem" }}
                            ></i>
                            <span>{i18n.t("download_attatchment")}</span>
                          </div>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm rounded-2 border"
                            onClick={() => {
                              if (!this.state.auction_details.file_terms) {
                                swal(
                                  "Failed",
                                  "No files were found!!. Please contact mzadcom administrator",
                                  "error"
                                );
                                return;
                              }
                              if (this.state.auction_details.file_terms) {
                                window.open(
                                  this.state.auction_details.file_terms,
                                  "_blank"
                                );
                              }
                            }}
                          >
                            <i className="fa fa-eye" />
                            &nbsp;
                            {i18n.t("preview")}{" "}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="card mb-xl-0 mt-2 rounded-4 border">
                    <h2
                      className="accordion-header fs-5 fs-md-4"
                      id="flush-headingOne"
                    >
                      <button
                        className="w-100 rounded rounded-4 flex-fill px-3 py-1 bg-light d-flex gap-1 border justify-content-between"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="true"
                        aria-controls="flush-collapseOne"
                        onClick={() => {
                          this.setState({
                            show_bank_panel: !this.state.show_bank_panel,
                          });
                        }}
                      >
                        <div>
                          <img
                            className="img-fluid"
                            width="25"
                            height="26"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/mdi_bank.png"
                            }
                            alt=""
                          />
                          <span className="custom-text3 m-0 p-1">
                            {i18n.t("bank_details")}
                          </span>
                        </div>
                        <div>
                          <i
                            className={
                              this.state.show_bank_panel
                                ? "fa fa-angle-up p-1 px-2 rounded-circle border"
                                : "fa fa-angle-down p-1 px-2 rounded-circle border"
                            }
                            style={{ background: "#e8f4fa" }}
                          />
                        </div>
                      </button>
                    </h2>
                    <div className="card-body p-2 py-0">
                      {this.state.show_bank_panel && (
                        <div
                          className="leading-Automatic border rounded-4 p-2 my-2"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          <p className="pb-0 mb-1 fs-9">
                            Account Name : Mzadcom Smart Auction Solutions LLC
                          </p>
                          <p className="pb-0 mb-1 fs-9">
                            Account Number : 0440061839220015
                          </p>
                          <p className="pb-0 mb-1 fs-9">
                            SWIFT CODE : BMUSOMRX
                          </p>
                          <p className="pb-0 mb-1 fs-9">
                            Currency : OMANI RIYAL
                          </p>
                          <p className="pb-0 fs-9">Bank/Branch : Gala.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {this.state.need_online_payment && this.state.open_popup && (
            <Modal
              size="lg"
              show={this.openModal}
              onHide={this.closeModal}
              animation={true}
              dialogClassName="modal-90w"
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  {i18n.t("warranty_policy")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="col-xl-12">
                  <div className="card mb-xl-0 mt-5">
                    {lng === "ar" && (
                      <div className="card-body">
                        <h2 className="mb-4 rtl">
                          مبلغ الضمان وسياسة الاسترداد
                        </h2>
                        <div
                          className="leading-Automatic rtl"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          <p className="rtl">
                            1) على الراغبين بالمشاركة في المزاد الإلكتروني إيداع
                            مبلغ الضمان (
                            <b>{this.state.auction_details.payment_amount}</b>{" "}
                            ريال عماني).
                          </p>
                          <p className="rtl">
                            2) مبلغ التأمين قابل للاسترداد. ومع ذلك ، فإن مبلغ
                            التحويل غير قابل للاسترداد وهو 1.5٪ لبطاقات الخصم
                            العمانية و 2٪ لبطاقات الائتمان.
                          </p>
                          <p className="rtl">
                            3) يكون مبلغ الضمان منفصلاً عن قيمة الصنف الذي يجب
                            دفعه بعد الفوز بالمزاد.
                          </p>
                          <p className="rtl">
                            5) سيتم إرجاع مبلغ الضمان إلى مقدم العطاء بعد انتهاء
                            المزاد ، بالنسبة للفائزين ، سيتم استرداد هذا المبلغ
                            بعد الدفعة النهائية واستكمال العملية.
                          </p>
                          <p className="rtl">
                            6) يتم رد المبالغ المستردة في غضون 1 إلى 5 أيام عمل
                            لعملاء بنك مسقط ، ولكن قد تستغرق العملية وقتًا أطول
                            لعملاء البنوك الأخرى.
                          </p>
                          <p className="rtl">
                            7) عند إعادة مبلغ الضمان إلى حسابك ، قد لا تتلقى
                            رسالة نصية قصيرة. يرجى التحقق من حسابك من خلال تطبيق
                            الهاتف الذكي الخاص بالبنك أو كشف الحساب.
                          </p>
                        </div>
                      </div>
                    )}

                    {lng !== "ar" && (
                      <div className="card-body">
                        <h2 className="mb-4">
                          Warranty amount and refund policy
                        </h2>
                        <div
                          className="leading-Automatic"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          <p className="">
                            1) Those wishing to participate in the online
                            auction must deposit the guarantee amount (
                            <b>{this.state.auction_details.payment_amount}</b>
                            &nbsp; Omani Riyals).
                          </p>
                          <p className="">
                            2) The security deposit is refundable. However, the
                            transfer amount is non-refundable and is
                            <b>1.5%</b> for Omani debit cards and <b>2%</b> for
                            credit cards.
                          </p>
                          <p className="">
                            3) The amount of the security is separate from the
                            value of the item, which must be paid after winning
                            the auction.
                          </p>
                          <p className="">
                            5) The security amount will be returned to the
                            bidder after the completion of the auction, for the
                            winners, this amount will be refunded after the
                            final payment and completion of the process.
                          </p>
                          <p className="">
                            6) Refunds are made within 1 to 5 working days for
                            bank muscat customers, but the process may take
                            longer for customers of other banks.
                          </p>
                          <p className="">
                            7) When the guarantee amount is refunded to your
                            account, you may not receive an SMS. Please verify
                            your account through the bank's smartphone
                            application or statement of account.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="text-center mb-3 mt-3">
                    <button
                      className="btn btn-info  mb-0 me-2 text-white"
                      onClick={(e) => {
                        this.setState({
                          selected_price: debit_amount * 1000,
                        });
                        this.saveEnroll(e);
                      }}
                      disabled={!this.state.t_c_acept || this.state.is_payment}
                    >
                      {!this.state.is_payment && (
                        <span>
                          {i18n.t("debit_card_1")}
                          <br /> {debit_amount}
                        </span>
                      )}

                      {this.state.is_payment && <span>Loading...</span>}
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-info  mb-0 me-2 text-white"
                      onClick={(e) => {
                        this.setState({
                          selected_price: credit_amount * 1000,
                        });
                        this.saveEnroll(e);
                      }}
                      disabled={!this.state.t_c_acept || this.state.is_payment}
                    >
                      {!this.state.is_payment && (
                        <span>
                          {i18n.t("credit_card_2")}
                          <br /> {credit_amount}
                        </span>
                      )}

                      {this.state.is_payment && <span>Loading...</span>}
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </React.Fragment>
    );
  }
}
